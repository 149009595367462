import React from "react";

import TwitterIcon from "@mui/icons-material/Twitter";
import { Button } from "@mui/material";
import { tweet, TweetContent } from "../../lib/tweets";

export type TweetButtonProps = {
  handler?: () => void;
  tweetContent?: TweetContent;
};

export const TweetButton: React.FC<TweetButtonProps> = (props) => {
  const handler =
    props.handler ??
    (() => {
      if (props.tweetContent) {
        tweet(props.tweetContent);
      }
    });
  return (
    <Button
      variant="contained"
      startIcon={<TwitterIcon />}
      color="info"
      sx={{ m: 1 }}
      onClick={handler}
    >
      {props.children ?? "ツイート"}
    </Button>
  );
};
