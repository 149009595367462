import { Container, Typography } from "@mui/material";
import { useOneProblemData } from "../../hooks/problemHooks";
import { UserAndGuildAndUid } from "../../interface";
import { NowLoading } from "../NowLoading";
import AnswerKeyInput, { BehaviorHandler } from "./AnswerKeyInput";
import { ContentDataViewer } from "./ContentDataViewer";

export const ProblemArea = ({
  problemId,
  userAndGuildAndUid,
  now,
  behaviorHandler,
  setIsBackdropOpened,
}: {
  problemId: string;
  userAndGuildAndUid: UserAndGuildAndUid;
  now: Date | undefined;
  behaviorHandler: BehaviorHandler;
  setIsBackdropOpened: (value: React.SetStateAction<boolean>) => void;
}) => {
  const { uid } = userAndGuildAndUid;
  const problemData = useOneProblemData(userAndGuildAndUid, problemId, now);

  return (
    <Container maxWidth="md">
      {problemData ? (
        <>
          <ContentDataViewer prop={problemData.problemData} />

          <AnswerKeyInput
            problemId={problemId}
            answerKeyArray={problemData.answerKeyArray}
            useLocalStorage={problemData.useLocalStorage}
            behaviorHandler={behaviorHandler}
            uid={uid}
            setIsBackdropOpened={setIsBackdropOpened}
          />
        </>
      ) : (
        <Typography>Now loading...</Typography>
      )}
    </Container>
  );
};
