import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { changeGuildOwner } from "../../../api/cloudFunctionsCaller";
import { useGuildMemberNameList } from "../../../hooks/guildDataHooks";
import { Guild, User } from "../../../interface";
import { BackdropWithCircle } from "../backdrop/BackdropWithCircle";

export const ChangeGuildOwner = ({
  guildData,
  userData,
  uid,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
}) => {
  const [newOwnerId, setNewOwnerId] = useState("");

  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setNewOwnerId(event.target.value);
  };

  const guildMemberNameList = useGuildMemberNameList(guildData);

  useEffect(() => {
    setNewOwnerId("");
  }, [guildMemberNameList]);

  const newOwnerName = useMemo(() => {
    return guildMemberNameList.find((e) => e.memberId === newOwnerId)
      ?.memberName;
  }, [guildMemberNameList, newOwnerId]);

  const [ownerChangeDialogOpen, setOwnerChangeDialogOpen] = useState(false);
  const handleOwnerChangeDialogOpen = () => {
    setOwnerChangeDialogOpen(true);
  };
  const handleOwnerChangeDialogClose = () => {
    setOwnerChangeDialogOpen(false);
  };

  const [ownerChangeResultDialogOpen, setOwnerChangeResultDialogOpen] =
    useState(false);
  const handleOwnerChangeResultDialogOpen = () => {
    setOwnerChangeResultDialogOpen(true);
  };
  const handleOwnerChangeResultDialogClose = () => {
    setOwnerChangeResultDialogOpen(false);
  };

  const [noSelectErrorDialogOpen, setNoSelectErrorDialogOpen] = useState(false);
  const handleNoSelectErrorDialogOpen = () => {
    setNoSelectErrorDialogOpen(true);
  };
  const handleNoSelectErrorDialogClose = () => {
    setNoSelectErrorDialogOpen(false);
  };

  const [ownerChangeResult, setOwnerChangeResult] = useState(false);

  const handleOwnerChangeButton = async (memberId: string) => {
    setIsBackdropOpened(true);
    handleOwnerChangeDialogClose();
    const result =
      typeof userData?.joinedGuildId === "string"
        ? await changeGuildOwner(uid, userData.joinedGuildId, memberId)
        : { data: false };
    setIsBackdropOpened(false);
    setOwnerChangeResult((result?.data as boolean) ?? false);
    handleOwnerChangeResultDialogOpen();
  };

  return (
    <>
      <BackdropWithCircle isBackdropOpened={isBackdropOpened} />
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="simple-select-label">newOwner</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={newOwnerId}
            label="新オーナー"
            onChange={handleChange}
          >
            {guildMemberNameList.map((data) => {
              if (data.memberId !== uid) {
                return (
                  <MenuItem value={data.memberId} key={data.memberId}>
                    ユーザー名 : {data.memberName}, ID : {data.memberId}
                  </MenuItem>
                );
              } else {
                return <></>;
              }
            })}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={() => {
            if (newOwnerId === "") {
              handleNoSelectErrorDialogOpen();
            } else {
              handleOwnerChangeDialogOpen();
            }
          }}
        >
          ギルドマスターを変更
        </Button>
      </Box>

      <Dialog
        open={ownerChangeDialogOpen}
        onClose={handleOwnerChangeDialogClose}
        aria-labelledby="alert-Ownerchange-title"
        aria-describedby="alert-Ownerchange-description"
      >
        <DialogTitle id="alert-Ownerchange-title">
          ギルドマスターの譲渡
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-Ownerchange-description">
            ユーザー名:{newOwnerName}
            <br />
            ID:{newOwnerId}
            <br />
            このメンバーにギルドマスターを譲渡しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOwnerChangeDialogClose}>キャンセル</Button>
          <Button
            onClick={() => {
              handleOwnerChangeButton(newOwnerId);
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ownerChangeResultDialogOpen}
        onClose={handleOwnerChangeResultDialogClose}
        aria-labelledby="alert-OwnerchangeResult-title"
        aria-describedby="alert-OwnerchangeResult-description"
      >
        <DialogTitle id="alert-OwnerchangeResult-title">
          ギルドマスターの譲渡
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-OwnerchangeResult-description">
            {ownerChangeResult ? (
              <>
                ユーザー名:{newOwnerName}
                <br />
                ID:{newOwnerId}
                <br />
                にギルドマスターを譲渡しました
              </>
            ) : (
              <>
                ギルドマスターの譲渡に失敗しました。時間をおいてもう一度お試しください。
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOwnerChangeResultDialogClose}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={noSelectErrorDialogOpen}
        onClose={handleNoSelectErrorDialogClose}
        aria-labelledby="alert-noSelectError-title"
        aria-describedby="alert-noSelectError-description"
      >
        <DialogTitle id="alert-noSelectError-title">
          ギルドマスターの譲渡
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-noSelectError-description">
            ギルドマスター譲渡先のユーザーが選択されていません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoSelectErrorDialogClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
