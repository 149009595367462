import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Timestamp } from "firebase/firestore";
import { useRankingDataWithName } from "../../hooks/rankingHooks";
import { timestampToString } from "../../lib/timestampToString";
import { NowLoading } from "../NowLoading";

export const RankingDisplay = ({ problemId }: { problemId: string }) => {
  const rankingDataWithName = useRankingDataWithName(problemId, "correct");
  return (
    <>
      <Accordion sx={{ mt: 1, width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ my: 0, py: 0 }}
        >
          <Typography variant="h5" component="h1" sx={{ my: 1 }}>
            解答記録者一覧 ({rankingDataWithName?.length ?? 0})
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mb: 1, pb: 1 }}>
          {rankingDataWithName ? (
            <RankingTable rows={rankingDataWithName} />
          ) : rankingDataWithName === undefined ? (
            <Typography>正解者はいません。</Typography>
          ) : (
            <NowLoading />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const RankingTable = ({
  rows,
}: {
  rows: { userName: string; time: Timestamp; uid: string }[];
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>順位</TableCell>
            <TableCell>正解者名</TableCell>
            <TableCell>正解時刻</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              key={row.uid}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                {(row.userName ?? "") === "" ? "名無しの権兵衛" : row.userName}
              </TableCell>
              <TableCell>{timestampToString(row.time)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
