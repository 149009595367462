import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../../api/cloudFunctionsCaller";
import { User } from "../../../interface";
import { NowLoading } from "../../NowLoading";
import { BackdropWithCircle } from "../backdrop/BackdropWithCircle";

//firebase authにはログインしているがSQL上のデータがない場合にのみ表示するページ

type FirstLoginProp = {
  uid: string;
  needCreateUser: boolean | undefined;
  setNeedCreateUser: (needCreateUser: boolean) => void;
};

export const FirstLogin = (prop: FirstLoginProp) => {
  const isLogined = prop.uid !== "";
  const [userNameInputValue, setUserNameInputValue] = useState("");
  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const navigate = useNavigate();
  const handleUserNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setUserNameInputValue(newName);
  };

  const handleButton = async () => {
    setIsBackdropOpened(true);
    const result = await createUser(prop.uid, userNameInputValue);
    setIsBackdropOpened(false);
    if (result) {
      prop.setNeedCreateUser(false);
      navigate("/firstlogin/success");
    }
  };

  return (
    <>
      {prop.needCreateUser ? (
        <Container sx={{ maxWidth: "md", my: 5 }}>
          <BackdropWithCircle isBackdropOpened={isBackdropOpened} />
          <Grid item xs={12}>
            <Typography variant="h3">アカウント新規作成</Typography>
          </Grid>
          <TextField
            variant="outlined"
            label="ユーザー名"
            value={userNameInputValue}
            onChange={handleUserNameInputValue}
          />
          <Button variant="contained" onClick={handleButton}>
            アカウントを作成する
          </Button>
        </Container>
      ) : prop.needCreateUser === undefined ? (
        <NowLoading />
      ) : !isLogined ? (
        <div>アカウント作成にはログインが必要です。</div>
      ) : (
        <div>
          既にアカウントは作成されています。アカウント作成について問題が生じた場合はお問い合わせよりご連絡ください。
        </div>
      )}
    </>
  );
};
