import { Grid } from "@mui/material";
import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";
import { CanvasUtil } from "./canvasConfig/CanvasConfig";
import { imageHeight, imageWidth } from "./canvasConfig/canvasVariable";

const CANVAS_ID = "canvas";

type Size = { height: number; width: number };

const resizeCanvas = (
  imageUrl: string,
  canvasWidth: number,
  canvas: fabric.Canvas | undefined,
  initialSize: Size
) => {
  fabric.Image.fromURL(imageUrl, (image) => {
    if (!image.width || !image.height || !canvas) {
      return;
    }
    const canvasHeight = (canvasWidth / image.width) * image.height;
    canvas.setZoom(canvasWidth / initialSize.width);
    canvas.setWidth(canvasWidth);
    canvas.setHeight(canvasHeight);
    canvas.setBackgroundImage(image, () => {
      image.scaleToHeight(canvasHeight);
      image.scaleToWidth(canvasWidth);
      canvas.renderAll();
    });
  });
};

export const Canvas = ({ imageUrl }: { imageUrl: string }) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | undefined>(undefined);
  const canvasLapper = useRef<HTMLDivElement>(null);
  const [initialSize, setInitialSize] = useState<Size>({
    height: imageHeight,
    width: imageWidth,
  });

  useEffect(() => {
    if (!canvasLapper.current || (imageUrl ?? "") === "") {
      return;
    }
    if (!canvas) {
      const width = canvasLapper.current.offsetWidth;
      const height = (width * imageHeight) / imageWidth;
      const initialCanvas = new fabric.Canvas(CANVAS_ID, {
        isDrawingMode: true, // 手書きモード
        allowTouchScrolling: true, //スマホで拡大出来るようにする
        width,
        height,
      });
      initialCanvas.freeDrawingBrush.width = 5;
      resizeCanvas(imageUrl, width, initialCanvas, { width, height });
      setInitialSize({ width, height });
      setCanvas(initialCanvas);
    }
  }, [canvas, imageUrl]);

  window.onresize = () => {
    if (canvasLapper.current && canvas && imageUrl) {
      resizeCanvas(
        imageUrl,
        canvasLapper.current.offsetWidth,
        canvas,
        initialSize
      );
    }
  };

  return (
    <>
      <Grid component="div" sx={{ my: 1 }}>
        <div ref={canvasLapper}>
          <canvas id={CANVAS_ID} />
        </div>
        <CanvasUtil canvas={canvas} setCanvas={setCanvas} />
      </Grid>
    </>
  );
};
