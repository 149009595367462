import { Slider } from "@mui/material";
import { fabric } from "fabric";
import { useState } from "react";

type Prop = {
  canvas: fabric.Canvas | undefined;
  setCanvas: (prop: fabric.Canvas) => void;
};

export const PenWidthSelect = (prop: Prop) => {
  const { canvas, setCanvas } = prop;
  const [penWidth, setPenWidth] = useState(5);

  const handlePenWidth = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      return;
    }
    setPenWidth(newValue);
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.freeDrawingBrush.width = newValue;
      setCanvas(newCanvas);
    }
  };
  return (
    <>
      <p>ペンの太さ:</p>
      <Slider
        aria-label="PenWidth"
        value={penWidth}
        valueLabelDisplay="auto"
        onChange={handlePenWidth}
        min={1}
        step={1}
        marks
        max={15}
      />
    </>
  );
};
