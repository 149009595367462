import pen_red from "../../image/pen_red.png";
import pen_orange from "../../image/pen_orange.png";
import pen_yellow from "../../image/pen_yellow.png";
import pen_lime from "../../image/pen_lime.png";
import pen_skyblue from "../../image/pen_skyblue.png";
import pen_purple from "../../image/pen_purple.png";
import pen_brown from "../../image/pen_brown.png";
import pen_black from "../../image/pen_black.png";
import pen_gray from "../../image/pen_gray.png";
import pen_white from "../../image/pen_white.png";
import { Stack } from "@mui/material";
import { useState } from "react";

//画像出典はいらすとや
const colorList = [
  { name: "red", color: "#e74c3c", icon: pen_red },
  { name: "orange", color: "#e67e22", icon: pen_orange },
  { name: "yellow", color: "#f1c40f", icon: pen_yellow },
  { name: "lime", color: "#2ecc71", icon: pen_lime },
  { name: "skyblue", color: "#3498db", icon: pen_skyblue },
  { name: "purple", color: "#9b59b6", icon: pen_purple },
  { name: "brown", color: "#8c5a24", icon: pen_brown },
  { name: "black", color: "#000000", icon: pen_black },
  { name: "gray", color: "#aaaaaa", icon: pen_gray },
  { name: "white", color: "#eeeeee", icon: pen_white },
];

const select: React.CSSProperties = {
  width: "50px",
  height: "50px",
  border: "solid 5px red",
};

const notSelect: React.CSSProperties = {
  width: "50px",
  height: "50px",
  padding: "5px",
};

type Prop = {
  canvas: fabric.Canvas | undefined;
  setCanvas: (prop: fabric.Canvas) => void;
};

export const ColorSelect = (prop: Prop) => {
  const { canvas, setCanvas } = prop;
  const [penColor, setPenColor] = useState("#000000");
  const changePenColor = (colorValue: string) => {
    //colorvalue sample: #fff, #c0ffee, etc.
    setPenColor(colorValue);
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.freeDrawingBrush.color = colorValue;
      setCanvas(newCanvas);
    }
  };
  return (
    <>
      <p>カラーパレット:</p>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0.5}
        sx={{ overflowX: "auto", height: "75px" }}
      >
        {colorList.map((v) => (
          <div
            style={penColor === v.color ? select : notSelect}
            key={v.name}
            onClick={() => {
              changePenColor(v.color);
            }}
          >
            <img
              src={v.icon}
              alt={v.name}
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          </div>
        ))}
      </Stack>
    </>
  );
};
