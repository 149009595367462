import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fab, Typography } from "@mui/material";
import React, { useState } from "react";
import { Guild, User, UserAndGuildAndUid } from "../interface";
import { ScrollTop } from "./button/ScrollTop";
import {
  ThumbnailFilterMethod,
  ThumbnailSortMethod,
} from "./problemSelectContents/thumbnailFilter/lib/types";
import { ThumbnailSortAndFilterMethodHandler } from "./problemSelectContents/thumbnailFilter/MethodHandler";
import { ThumbnailViewer } from "./problemSelectContents/ThumbnailViewer";
import { ThumbnailViewer2 } from "./problemSelectContents/ThumbnailViewer2";

const ProblemSelect = ({
  guildData,
  userData,
  uid,
  now,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
  now: Date | undefined;
}) => {
  /*
  const handlePagination = React.useCallback((e) => {
    const month = e.target.innerText;
    const offset = {
      // @ts-ignore
      top: document.querySelector(`#month-${month}`)?.offsetTop ?? 0,
      // @ts-ignore
      left: document.querySelector(`#month-${month}`)?.offsetLeft ?? 0,
      behavior: "smooth",
    };
    console.log(document.querySelector(`#month-${month}`));
    // @ts-ignore
    window.scrollTo(offset);
  }, []);
*/

  const [sortMethod, setSortMethod] = useState<ThumbnailSortMethod>({
    type: "problemStartAt",
    ascending: true,
  });

  const [filterMethod, setFilterMethod] = useState(
    new Array<ThumbnailFilterMethod>({ type: "null" })
  );
  const userAndGuildAndUid: UserAndGuildAndUid = {
    uid,
    userData,
    guildData,
  };

  return (
    <>
      <div>
        {/*(uid ?? "") !== "" ? (
          <div>ログイン済</div>
        ) : (
          <div>
            <Typography variant="h5" component="h1" sx={{ m: 2 }}>
              ログインすることで解答状況を記録できます。
            </Typography>
          </div>
        )*/}

        {/*<Pagination
          count={isGuildRevealed109Problem ? 13 : 12}
          boundaryCount={isGuildRevealed109Problem ? 13 : 12}
          hidePrevButton
          hideNextButton
          size="large"
          onClick={handlePagination}
          id="back-to-top-anchor"
/>*/}
        {/*

        <ThumbnailSortAndFilterMethodHandler
          setSortMethod={setSortMethod}
          setFilterMethod={setFilterMethod}
          filterMethod={filterMethod}
        />
        */}
        <ThumbnailViewer2
          userAndGuildAndUid={userAndGuildAndUid}
          thumbnailSortAndFilterCondition={{
            thumbnailFilter: filterMethod,
            thumbnailSort: sortMethod,
          }}
          now={now}
        />
      </div>
      <ScrollTop>
        <Fab color="secondary" size="large" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default ProblemSelect;
