import { OpeningCondition, ProblemProgress } from "../interface";
import { idConditionChecker } from "./idConditionChecker";
import { problemConditionChecker } from "./problemConditionChecker";
import { timeConditionChecker } from "./timeConditionChecker";

export const conditionChecker = (
  openingCondition: OpeningCondition,
  problemProgress: Map<string, ProblemProgress>,
  now: Date | undefined
) => {
  if (openingCondition === null) {
    return false;
  }
  switch (openingCondition.conditionType) {
    case "problem":
      return problemConditionChecker(openingCondition, problemProgress);
    case "time":
      return timeConditionChecker(openingCondition, now);
    case "id":
      return idConditionChecker(openingCondition);
    case "none":
      return true;
    default:
      return false;
  }
};
