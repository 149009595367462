import {
  Problem,
  Thumbnail,
  User,
  ProblemFeedBack,
  UserWithId,
  ProblemProgress,
  AnswerKeyObj,
  SolvedPlayerObj,
  FavoritedPlayerObj,
  PlayerCommentObj,
  Guild,
  ContentDataObj,
  Solution,
} from "../interface";

export const initialUser: User = {
  userName: null,
  problemProgress: new Map<string, ProblemProgress>(),
  picture: null,
};

export const initialUserWithId: UserWithId = {
  ...initialUser,
  uid: null,
};

export const initialThumbnail: Thumbnail = {
  author: null,
  title: null,
  problemStartAt: null,
  createdAt: null,
  updatedAt: null,
  firstAppearance: null,
  thumbnailImage: null,
  authorComment: null,
  difficulty: new Array<{ icon: string }>(),
  tag: new Array<string>(),
  appearanceCondition: null,
  openingCondition: null,
};

export const initialProblem: Problem = {
  ...initialThumbnail,
  answerKeyArray: new Array<AnswerKeyObj>(),
  problemData: new Array<ContentDataObj>(),
  useLocalStorage: null,
};

export const initialSolution: Solution = {
  ...initialThumbnail,
  solutionData: new Array<ContentDataObj>(),
};

export const initialProblemFeedBack: ProblemFeedBack = {
  solvedPlayer: new Array<SolvedPlayerObj>(),
  playerComment: new Array<PlayerCommentObj>(),
  favoritedPlayer: new Array<FavoritedPlayerObj>(),
};

export const initialGuild: Guild = {
  name: null,
  memberIds: new Array<string>(),
  problemProgress: new Map<string, ProblemProgress>(),
  active: false,
  guildOwner: null,
};
