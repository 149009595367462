import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Backdrop,
  CircularProgress,
  Container,
  Paper,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useIsProblemRevealed,
  useIsProblemSolved,
  useOneThumbnail,
} from "../hooks/problemHooks";
import { BehaviorId, Guild, User, UserAndGuildAndUid } from "../interface";
import { tweet } from "../lib/tweets";
import { HomeButton } from "./button/HomeButton";
import { TweetButton } from "./button/TweetButton";
import { NotFound } from "./NotFound";
import { NowLoading } from "./NowLoading";
import { CorrectDialog } from "./problemContents/dialog/CorrectDialog";
import { WrongDialog } from "./problemContents/dialog/WrongDialog";
import { ProblemArea } from "./problemContents/ProblemArea";
import { SolutionArea } from "./problemContents/SolutionArea";
import { RankingDisplay } from "./ranking/Ranking";
import { PageTitle } from "./typography/PageTitle";

export const ProblemPage = (prop: {
  userData: User;
  uid: string;
  guildData: Guild;
  now: Date | undefined;
}) => {
  const { problemId } = useParams<{ problemId: string }>();
  console.log(problemId);
  return (
    <>
      {problemId === undefined ? (
        <NotFound />
      ) : (
        <Problem {...prop} problemId={problemId} />
      )}
    </>
  );
};

const Problem = ({
  userData,
  uid,
  guildData,
  now,
  problemId,
}: {
  userData: User;
  uid: string;
  guildData: Guild;
  now: Date | undefined;
  problemId: string;
}) => {
  const userAndGuildAndUid: UserAndGuildAndUid = {
    uid,
    userData,
    guildData,
  };
  //解答履歴がサーバーに保存され、ローカルのデータが変更されるまでに時間がかかるので、
  //正解が送信されたときtrueになるopenSolutionTabも利用して解説タブを開く
  //see also: handleCorrectAnswer

  const [openSolutionTab, setOpenSolutionTab] = useState(false);
  const isProblemSolved = useIsProblemSolved(userAndGuildAndUid, problemId);

  useEffect(() => {
    setOpenSolutionTab(openSolutionTab || isProblemSolved);
  }, [isProblemSolved, openSolutionTab]);

  const thumbnailData = useOneThumbnail(problemId);
  const isProblemRevealed = useIsProblemRevealed(
    userAndGuildAndUid,
    problemId,
    now
  );

  //TODO ツイートテキスト調整
  const handleChallengeTweet = () => {
    tweet({
      text: `${thumbnailData?.title ?? "abyssの難問"}に挑戦します！`,
      url: `https://abyss.logicpuzzle.app/share/${problemId}`,
      hashtags: ["#abyssOfPuzzle"],
    });
  };

  const handleSolvedTweet = () => {
    tweet({
      text: `${thumbnailData?.title ?? "abyssの難問"}を解き明かしました！`,
      url: `https://abyss.logicpuzzle.app/share/${problemId}`,
      hashtags: ["#abyssOfPuzzle"],
    });
  };

  const [tabValue, setTabValue] = React.useState("problem");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [successOpen, setSuccessOpen] = useState(false);
  const [wrongOpen, setWrongOpen] = useState(false);
  const [isBackdropOpened, setIsBackdropOpened] = useState(false);
  const [openSolutionForGuest, setOpenSolutionForGuest] = useState(false);

  const handleClose = () => {
    setSuccessOpen(false);
    setWrongOpen(false);
  };

  const handleCorrectAnswer = async () => {
    setOpenSolutionTab(true);
    setTabValue("solution");
    setSuccessOpen(true);
    setOpenSolutionForGuest(true);
  };
  const handleWrongAnswer = async () => {
    setWrongOpen(true);
  };
  const behaviorHandler = new Map<BehaviorId, () => Promise<void>>([
    ["correct", handleCorrectAnswer],
    ["wrong", handleWrongAnswer],
  ]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBackdropOpened}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {thumbnailData ? (
        <Paper sx={{ maxWidth: "lg", margin: "auto" }}>
          <Stack style={{ margin: "0% 10px" }} spacing={1}>
            <PageTitle>{thumbnailData.title}</PageTitle>
            <p>by {thumbnailData.author}</p>
          </Stack>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="問題" value="problem" />
                <Tab
                  label="解説"
                  value="solution"
                  disabled={!openSolutionTab}
                />
              </TabList>
            </Box>
            <TabPanel value="problem">
              <ProblemArea
                problemId={problemId}
                behaviorHandler={behaviorHandler}
                userAndGuildAndUid={userAndGuildAndUid}
                now={now}
                setIsBackdropOpened={setIsBackdropOpened}
              />
            </TabPanel>
            <TabPanel value="solution">
              <SolutionArea
                problemId={problemId}
                userAndGuildAndUid={userAndGuildAndUid}
                now={now}
                openSolutionForGuest={openSolutionForGuest}
              />
            </TabPanel>
          </TabContext>
          <Container maxWidth="md">
            {openSolutionTab ? (
              <TweetButton handler={handleSolvedTweet}>
                正解した事をツイート
              </TweetButton>
            ) : (
              <TweetButton handler={handleChallengeTweet}>
                挑戦する事をツイート
              </TweetButton>
            )}

            <HomeButton />
            <RankingDisplay problemId={problemId} />
          </Container>
        </Paper>
      ) : isProblemRevealed === undefined || thumbnailData === undefined ? (
        <NowLoading />
      ) : (
        <NotFound />
      )}

      <CorrectDialog open={successOpen} closeHandler={handleClose}>
        {thumbnailData?.title}を見事解き明かした！
        <br />
        正解したことをシェアできます。
        <br />
        <TweetButton handler={handleSolvedTweet} />
      </CorrectDialog>
      <WrongDialog open={wrongOpen} closeHandler={handleClose}>
        もう一度お考えください。
      </WrongDialog>
    </>
  );
};
