import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "firebase/functions";
import React, { useState } from "react";
import { saveUserName } from "../../../api/cloudFunctionsCaller";
import { BackdropWithCircle } from "../backdrop/BackdropWithCircle";

export const ChangeUserName = ({ uid }: { uid: string }) => {
  const [userNameInputValue, setUserNameInputValue] = useState("");
  const [message, setMessage] = useState("");
  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const clearMessage = () => {
    setMessage("");
  };
  const handleUserNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const newName = e.target.value;
    setUserNameInputValue(newName);
  };
  const handleButton = async () => {
    setIsBackdropOpened(true);
    await saveUserName({ uid, userNameInputValue, setMessage });
    setIsBackdropOpened(false);
  };

  return (
    <>
      <BackdropWithCircle isBackdropOpened={isBackdropOpened} />
      <Card sx={{ my: 2 }}>
        <CardContent>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              ユーザー名の設定
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            <TextField
              variant="outlined"
              label="ユーザー名"
              value={userNameInputValue}
              onChange={handleUserNameInputValue}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            <Button variant="contained" onClick={handleButton}>
              変更を保存する
            </Button>
            <div>{message}</div>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
