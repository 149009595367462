import { Container } from "@mui/material";
import { useOneSolutionData } from "../../hooks/problemHooks";
import { UserAndGuildAndUid } from "../../interface";
import { NowLoading } from "../NowLoading";
import { ContentDataViewer } from "./ContentDataViewer";

export const SolutionArea = ({
  problemId,
  userAndGuildAndUid,
  now,
  openSolutionForGuest,
}: {
  problemId: string;
  userAndGuildAndUid: UserAndGuildAndUid;
  now: Date | undefined;
  openSolutionForGuest: boolean;
}) => {
  const solutionData = useOneSolutionData(
    userAndGuildAndUid,
    problemId,
    now,
    openSolutionForGuest
  );
  return (
    <Container maxWidth="md">
      {solutionData ? (
        <ContentDataViewer prop={solutionData.solutionData} />
      ) : (
        <NowLoading />
      )}
    </Container>
  );
};
