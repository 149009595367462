import { useEffect, useState } from "react";
import { getServerOffset } from "../api/cloudFunctionsCaller";

//サーバー時刻を取得する
export const useTime = () => {
  const [serverOffset, setServerOffset] = useState<number | undefined>(
    undefined
  );
  const [time, setTime] = useState<Date | undefined>(undefined);
  useEffect(() => {
    const f = async () => {
      const result = await getServerOffset();
      if (result) {
        setServerOffset(result);
      }
    };
    f();
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (serverOffset) {
        const t = Date.now() + serverOffset;
        setTime(new Date(t));
      }
    }, 100);
  }, [serverOffset]);

  return time;
};
