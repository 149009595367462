import { Button, Grid, Icon, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { checkAnswer } from "../../api/cloudFunctionsCaller";
import { AnswerKeyObj, BehaviorId } from "../../interface";
import { hashText } from "../../util/hashText";

const toHalfWidth = (strVal: string) => {
  // 半角変換
  const halfVal = strVal.replace(/[！-～]/g, (tmpStr: string) => {
    // 文字コードをシフト
    return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
  });

  // 文字コードシフトで対応できない文字の変換
  return halfVal
    .replace(/”/g, '"')
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
};

export type BehaviorHandler = Map<BehaviorId, () => Promise<void>>;

export type AnswerKeyInputProp = {
  problemId: string;
  answerKeyArray: Array<AnswerKeyObj>;
  useLocalStorage: boolean | null;
  behaviorHandler: BehaviorHandler;
  uid: string;
  setIsBackdropOpened: (value: React.SetStateAction<boolean>) => void;
};

const AnswerKeyInput = (prop: AnswerKeyInputProp) => {
  const {
    problemId,
    answerKeyArray,
    useLocalStorage,
    behaviorHandler,
    uid,
    setIsBackdropOpened,
  } = prop;
  const [answerKeyInputValue, setAnswerKeyInputValue] = useState("");
  const [alreadyInput, setAlreadyInput] = useState(false);

  useEffect(() => {
    if (useLocalStorage) {
      const savedKey = window.localStorage.getItem(problemId);
      if (savedKey) {
        setAlreadyInput(true);
        setAnswerKeyInputValue(savedKey);
      } else {
        setAlreadyInput(false);
        setAnswerKeyInputValue("");
      }
    }
  }, [problemId, useLocalStorage]);

  const handleAnswerKeyInputValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newAnswerKeyInputValue = e.target.value;
    setAnswerKeyInputValue(newAnswerKeyInputValue);
  };

  const checkAnswerKey = async () => {
    setIsBackdropOpened(true);
    if (answerKeyInputValue === "") {
      handleBehavior("wrong");
      return;
    }
    const hashedAnswerKeyInputValue = await hashText(answerKeyInputValue);
    let isAnswerkeyHit = false;
    answerKeyArray.forEach((v) => {
      if (v.answer === hashedAnswerKeyInputValue) {
        isAnswerkeyHit = true;
      }
    });
    if (isAnswerkeyHit) {
      await answerKeyHit();
    } else {
      await handleBehavior("wrong");
    }
    setIsBackdropOpened(false);
  };

  const answerKeyHit = async () => {
    const result = await checkAnswer(uid, problemId, answerKeyInputValue);
    await handleBehavior(result.behaviorId);
  };

  const handleBehavior = async (behavior: BehaviorId) => {
    const handler = behaviorHandler.get(behavior);
    if (handler !== undefined) {
      await handler();
    }
    if (behavior === "correct" && useLocalStorage) {
      window.localStorage.setItem(problemId, answerKeyInputValue);
      setAlreadyInput(true);
    }
  };

  return (
    <Grid component="div" sx={{ mx: 1, my: 2 }}>
      <TextField
        type="text"
        value={answerKeyInputValue}
        label="Answer Key"
        disabled={alreadyInput}
        variant="outlined"
        onChange={(e) => handleAnswerKeyInputValue(e)}
        sx={{ mx: 1, my: 1 }}
      ></TextField>
      <Button
        variant="contained"
        onClick={checkAnswerKey}
        endIcon={<Icon>send</Icon>}
        sx={{ my: 2 }}
        size={"large"}
      >
        解答
      </Button>
    </Grid>
  );
};
export default AnswerKeyInput;
