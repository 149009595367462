import { Guild, Thumbnail, User } from "../../../../interface";
import { ThumbnailFilterMethod } from "./types";

export const thumbnailFilter = (
  thumbnailData: Map<string, Thumbnail>,
  thumbnailFilter: ThumbnailFilterMethod,
  userData: User,
  guildData: Guild
) => {
  const newThumbnailData = new Map<string, Thumbnail>();
  thumbnailData.forEach((v, key) => {
    if (filter(v, key, thumbnailFilter, userData, guildData)) {
      newThumbnailData.set(key, v);
    }
  });
  return newThumbnailData;
};

const filter = (
  thumbnail: Thumbnail,
  problemId: string,
  thumbnailFilter: ThumbnailFilterMethod,
  userData: User,
  guildData: Guild
): boolean => {
  switch (thumbnailFilter.type) {
    case "author":
      return thumbnailFilter.author !== undefined
        ? thumbnail.author === thumbnailFilter.author
        : true;
    case "tag":
      return thumbnailFilter.tag !== undefined
        ? thumbnail.tag.includes(thumbnailFilter.tag)
        : true;
    case "solvedByGuild":
      return thumbnailFilter.isSolved !== undefined
        ? guildData.problemProgress.has(problemId) === thumbnailFilter.isSolved
        : true;
    case "solvedByUser":
      return thumbnailFilter.isSolved !== undefined
        ? userData.problemProgress.has(problemId) === thumbnailFilter.isSolved
        : true;
    default:
      return true;
  }
};
