import StarIcon from "@mui/icons-material/Star";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItem,
  Paper,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material";
import { useGuildMemberNameList } from "../../../hooks/guildDataHooks";
import { useEffect, useState } from "react";
import { kickMember } from "../../../api/cloudFunctionsCaller";
import { Guild, User } from "../../../interface";
import { BackdropWithCircle } from "../backdrop/BackdropWithCircle";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ShowGuildMemberList = ({
  guildData,
  userData,
  uid,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
}) => {
  const [isKickButtonShown, setIsKickButtonShown] = useState(false);
  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const guildMemberNameList = useGuildMemberNameList(guildData);

  useEffect(() => {
    userData.joinedGuildId === userData.originalGuildId &&
    guildData.memberIds.length > 1
      ? setIsKickButtonShown(true)
      : setIsKickButtonShown(false);
  }, [userData.joinedGuildId, userData.originalGuildId, guildData.memberIds]);

  const [kickDialogOpen, setKickDialogOpen] = useState(false);
  const [kickResultDialogOpen, setKickResultDialogOpen] = useState(false);
  const [kickMemberId, setKickMemberId] = useState("");
  const [kickMemberName, setKickMemberName] = useState("");
  const [kickResult, setKickResult] = useState(false);

  const handleKickDialogOpen = (memberName: string, memberId: string) => {
    setKickMemberName(memberName);
    setKickMemberId(memberId);
    setKickDialogOpen(true);
  };
  const handleKickDialogClose = () => {
    setKickDialogOpen(false);
  };

  const handleKickResultDialogOpen = () => {
    setKickResultDialogOpen(true);
  };
  const handleKickResultDialogClose = () => {
    setKickResultDialogOpen(false);
  };

  const handleKickButton = async (memberId: string) => {
    setIsBackdropOpened(true);
    handleKickDialogClose();
    const result = await kickMember(uid, memberId);
    setKickResult((result?.data as boolean) ?? false);
    setIsBackdropOpened(false);
    handleKickResultDialogOpen();
  };

  return (
    <>
      <BackdropWithCircle isBackdropOpened={isBackdropOpened} />
      <Grid item xs={12}>
        {guildData.name ? (
          <>
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {guildData.name}
            </Typography>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {guildMemberNameList.map((data) => {
                let icon;

                if (data.memberId === guildData.guildOwner) {
                  icon = <StarIcon />;
                }

                return (
                  <ListItem key={data.memberId}>
                    <Chip
                      icon={icon}
                      label={data.memberName}
                      onDelete={
                        isKickButtonShown && data.memberId !== uid
                          ? () => {
                              handleKickDialogOpen(
                                data.memberName,
                                data.memberId
                              );
                            }
                          : undefined
                      }
                    />
                  </ListItem>
                );
              })}
            </Paper>
            <Dialog
              open={kickDialogOpen}
              onClose={handleKickDialogClose}
              aria-labelledby="alert-kickdialog-title"
              aria-describedby="alert-kickdialog-description"
            >
              <DialogTitle id="alert-kickdialog-title">
                ギルドからの強制退会
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-kickdialog-description">
                  ユーザー名:{kickMemberName}
                  <br />
                  ID:{kickMemberId}
                  <br />
                  このメンバーを退会させますか？
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleKickDialogClose}>キャンセル</Button>
                <Button
                  onClick={() => {
                    handleKickButton(kickMemberId);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={kickResultDialogOpen}
              onClose={handleKickResultDialogClose}
              aria-labelledby="alert-kickresultdialog-title"
              aria-describedby="alert-kickresultdialog-description"
            >
              <DialogTitle id="alert-kickresultdialog-title">
                ギルドからの強制退会
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-kickresultdialog-description">
                  {kickResult ? (
                    <>
                      ユーザー名:{kickMemberName}
                      <br />
                      ID:{kickMemberId}
                      <br />
                      のギルドからの強制退会に成功しました。
                    </>
                  ) : (
                    <>
                      強制退会処理に失敗しました。時間をおいて再度お試しください。
                    </>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleKickResultDialogClose}>閉じる</Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <>ギルドに参加していません。</>
        )}
      </Grid>
    </>
  );
};
