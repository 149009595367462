import { Button } from "@mui/material";
import { UrlContentDataObj } from "../../../interface";

export const UrlContentViewer = (prop: UrlContentDataObj) => {
  return (
    <div>
      {prop.data.useInlineFrame && (
        <iframe
          style={{ width: "100%", height: "auto" }}
          title="iframe"
          src={prop.data.url}
        ></iframe>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.href = prop.data.url;
        }}
      >
        {prop.data.buttonText}
      </Button>
    </div>
  );
};
