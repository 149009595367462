import { Button, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
export const HomeButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      startIcon={<Icon>apps</Icon>}
      onClick={() => {
        navigate("/");
      }}
    >
      サイトトップに戻る
    </Button>
  );
};
