import {
  Guild,
  Thumbnail,
  ThumbnailWithProblemId,
  User,
} from "../../../../interface";
import { thumbnailFilter } from "./thumbnailFilter";
import { thumbnailSort } from "./thumbnailSort";
import { ThumbnailSortAndFilterMethod } from "./types";

export const thumbnailSortAndFilter = (
  thumbnailData: Map<string, Thumbnail>,
  thumbnailSortAndFilterCondition: ThumbnailSortAndFilterMethod,
  userData: User,
  guildData: Guild
) => {
  thumbnailSortAndFilterCondition.thumbnailFilter.forEach((v) => {
    thumbnailData = new Map(
      thumbnailFilter(thumbnailData, v, userData, guildData)
    );
  });
  let thumbnailArray = new Array<ThumbnailWithProblemId>();
  thumbnailData.forEach((val, key) => {
    thumbnailArray.push({ problemId: key, ...val });
  });
  return thumbnailSort(
    thumbnailArray,
    thumbnailSortAndFilterCondition.thumbnailSort
  );
};
