import { useEffect, useRef, useState } from "react";
import { createUser } from "../api/cloudFunctionsCaller";
import { getUserDataOnce, loadUserData } from "../api/dataLoader";
import { initialUser } from "../data/initialData";
import { isGuildSystemOn } from "../igSystemConfig";
import { ProblemProgress, User, UserAndGuildAndUid } from "../interface";

//ギルド無効時にはuserの、有効時にはguildのproblemProgressを返す
export const useProgress = (prop: UserAndGuildAndUid) => {
  const { uid, userData, guildData } = prop;
  const [progress, setProgress] = useState(new Map<string, ProblemProgress>());
  useEffect(() => {
    if (isGuildSystemOn) {
      setProgress(guildData.problemProgress);
    } else {
      setProgress(userData.problemProgress);
    }
  }, [uid, userData.problemProgress, guildData.problemProgress]);

  return progress;
};

export const useIsLogined = (uid: string) => {
  const [isLogined, setIsLogined] = useState(true);

  useEffect(() => {
    setIsLogined(uid !== "");
  }, [uid]);

  return isLogined;
};

export const useUserData = (uid: null | string) => {
  const [userData, setUserData] = useState<User>(initialUser);
  /**
   * isUserDataLoaded
   * false: 処理中のためユーザーデータが適切なデータでない可能性がある
   * true: ユーザーデータが適切なデータである
   */
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  /**
   * needCreateUserData
   * undefined: 未処理
   * true: アカウント新規作成必要
   * false: アカウント新規作成不要
   */
  const [needCreateUser, setNeedCreateUser] = useState<undefined | boolean>(
    undefined
  );
  const stopUserDataSnapshot = useRef(() => {});
  useEffect(() => {
    switch (uid) {
      case null:
        //ログイン処理中
        break;
      case "":
        //ゲストモード
        stopUserDataSnapshot.current();
        setNeedCreateUser(false);
        setIsUserDataLoaded(true);
        setUserData(initialUser);
        break;
      default:
        stopUserDataSnapshot.current();
        getUserDataOnce(uid).then((newUserData) => {
          if (!newUserData) {
            setNeedCreateUser(true);
            setIsUserDataLoaded(true);
          } else {
            setNeedCreateUser(false);
            stopUserDataSnapshot.current = loadUserData(
              uid,
              setUserData,
              setIsUserDataLoaded
            );
          }
        });
        break;
    }
  }, [uid, needCreateUser]);
  return {
    userData,
    isUserDataLoaded,
    needCreateUser,
    setNeedCreateUser,
  };
};
