import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { saveGuildName } from "../../../api/cloudFunctionsCaller";
import { BackdropWithCircle } from "../backdrop/BackdropWithCircle";

export const ChangeGuildName = ({
  isNewGuild,
  uid,
}: {
  isNewGuild: boolean;
  uid: string;
}) => {
  const [guildNameInputValue, setGuildNameInputValue] = useState("");
  const [message, setMessage] = useState("");

  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const clearMessage = () => {
    setMessage("");
  };
  const handleGuildNameInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const newName = e.target.value;
    setGuildNameInputValue(newName);
  };
  const handleButton = async () => {
    setIsBackdropOpened(true);
    await saveGuildName(uid, guildNameInputValue, setMessage);
    setMessage("saved.");
    setIsBackdropOpened(false);
  };

  return (
    <>
      <BackdropWithCircle isBackdropOpened={isBackdropOpened} />
      <Grid item xs={12} sx={{ my: 2 }}>
        {isNewGuild && (
          <p>ギルド名を入力することで新規ギルドを作成できます。</p>
        )}
        <TextField
          variant="outlined"
          label="ギルド名"
          value={guildNameInputValue}
          onChange={handleGuildNameInputValue}
        ></TextField>

        <Grid item xs={12} component="div" sx={{ my: 2 }}>
          <Button variant="contained" color="primary" onClick={handleButton}>
            {isNewGuild ? "ギルドを新規作成" : "変更を保存する"}
          </Button>
          <div>{message}</div>
        </Grid>
      </Grid>
    </>
  );
};
