import { Typography } from "@mui/material";
import { ReactChild, ReactFragment, ReactPortal } from "react";
export const SectionTitle = (props: {
  children:
    | boolean
    | ReactChild
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
}) => {
  return (
    <Typography variant="h5" component="p" sx={{ mt: 4, mb: 2 }}>
      {props.children}
    </Typography>
  );
};
