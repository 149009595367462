import { ProblemCondition, ProblemProgress } from "../interface";

export const problemConditionChecker = (
  problemCondition: ProblemCondition,
  problemProgress: Map<string, ProblemProgress>
) => {
  let ret = true;
  problemCondition.data.problem.forEach((v) => {
    ret = ret && (problemProgress.get(v)?.has("correct") ?? false);
  });
  return ret;
};
