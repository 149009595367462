import { Grid, Stack } from "@mui/material";
import { ContentDataObj } from "../../interface";
import { IdContendViewer } from "./contentViewer/IdContentViewer";
import { ImageContentViewer } from "./contentViewer/ImageContentViewer";
import { TextContentViewer } from "./contentViewer/TextContentViewer";
import { UrlContentViewer } from "./contentViewer/UrlContentViewer";

export const ContentDataViewer = ({
  prop,
}: {
  prop: Array<ContentDataObj>;
}) => {
  return (
    <Stack spacing={3}>
      {prop.map((v, i) => {
        switch (v.dataType) {
          case "image":
            return <ImageContentViewer {...v} key={i} />;
          case "url":
            return (
              <Grid item key={i.toString()}>
                <UrlContentViewer {...v} />
              </Grid>
            );
          case "text":
            return <TextContentViewer {...v} key={i} />;
          case "id":
            return <IdContendViewer {...v} key={i} />;
          default:
            return <></>;
        }
      })}
    </Stack>
  );
};
