import { ThumbnailWithProblemId } from "../../../../interface";
import { ThumbnailSortMethod } from "./types";

export const thumbnailSort = (
  thumbnailArray: Array<ThumbnailWithProblemId>,
  thumbnailSortMethod: ThumbnailSortMethod
): Array<ThumbnailWithProblemId> => {
  switch (thumbnailSortMethod.type) {
    case "updatedAt":
      return thumbnailArray.sort((a, b) =>
        thumbnailSortMethod.ascending
          ? updateAtCompare(a, b)
          : -updateAtCompare(a, b)
      );
    case "problemStartAt":
      return thumbnailArray.sort((a, b) =>
        thumbnailSortMethod.ascending
          ? problemStartAtCompare(a, b)
          : -problemStartAtCompare(a, b)
      );
    default:
      return thumbnailArray;
  }
};

const updateAtCompare = (
  a: ThumbnailWithProblemId,
  b: ThumbnailWithProblemId
) => {
  if (!a.updatedAt) {
    if (!b.updatedAt) {
      return 0;
    } else return 1;
  }
  if (!b.updatedAt) {
    return -1;
  }
  return b.updatedAt.seconds - a.updatedAt.seconds;
};

const problemStartAtCompare = (
  a: ThumbnailWithProblemId,
  b: ThumbnailWithProblemId
) => {
  if (!a.problemStartAt) {
    if (!b.problemStartAt) {
      return 0;
    } else return 1;
  }
  if (!b.problemStartAt) {
    return -1;
  }
  return b.problemStartAt.seconds - a.problemStartAt.seconds;
};
