import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { loadGuildAdminData } from "../../../api/dataLoader";
import { makeGuildInvite } from "../../../api/cloudFunctionsCaller";
import { BackdropWithCircle } from "../backdrop/BackdropWithCircle";

export const MakeGuildInviteCode = ({
  guildId,
  guildName,
  uid,
}: {
  guildId: string;
  guildName: string | null;
  uid: string;
}) => {
  const [guildInviteCode, setGuildInviteCode] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const makeInviteCode = async () => {
    setGuildInviteCode("");
    setIsBackdropOpened(true);
    await makeGuildInvite(uid);
    loadGuildAdminData(guildId, setGuildInviteCode);
    setIsCopied(false);
    setIsBackdropOpened(false);
  };

  useEffect(() => {
    if (guildId) {
      loadGuildAdminData(guildId, setGuildInviteCode);
    }
  }, [guildId]);

  return (
    <>
      <BackdropWithCircle isBackdropOpened={isBackdropOpened} />

      <Grid xs={12} item>
        {guildName !== null ? (
          <>
            <p>
              <Button variant="contained" onClick={makeInviteCode}>
                招待コードを発行
              </Button>
            </p>
            <TextField
              variant="outlined"
              value={guildInviteCode}
              disabled={true}
            ></TextField>

            <Grid sx={{ my: 2 }}>
              <CopyToClipboard
                text={guildInviteCode}
                onCopy={() => setIsCopied(true)}
              >
                <Button component="p" variant="contained">
                  コピーする
                </Button>
              </CopyToClipboard>
            </Grid>
            {isCopied && <div>copied!</div>}
          </>
        ) : (
          <p>他のメンバーをギルドに招待する前にギルド名を設定してください</p>
        )}
      </Grid>
    </>
  );
};
