import { Button, Divider, Grid, Icon, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIsLogined } from "../hooks/userDataHooks";
import { isGuildSystemOn } from "../igSystemConfig";
import { Guild, User } from "../interface";
import { HomeButton } from "./button/HomeButton";

export type ConfigProps = {
  guildData: Guild;
  userData: User;
  uid: string;
};

export const Config = ({ uid }: { uid: string }) => {
  const navigate = useNavigate();

  const isLogined = useIsLogined(uid);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            各種設定
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon>person</Icon>}
            onClick={() => navigate("/config/user")}
          >
            ユーザー設定
          </Button>
          {isLogined && isGuildSystemOn && (
            <Button
              variant="contained"
              startIcon={<Icon>group</Icon>}
              onClick={() => navigate("/config/guild")}
            >
              ギルド設定
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <HomeButton />
        </Grid>

        <Divider />
      </Grid>
    </>
  );
};
