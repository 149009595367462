export type TweetContent = {
  text: string;
  url?: string;
  hashtags?: string[];
};

const stringArrayToString = (prop: string[]) => {
  let ret = "";
  prop.forEach((v, i) => {
    if (i !== 0) {
      ret += `,${v}`;
    } else {
      ret += v;
    }
  });
  return ret;
};

export const tweet = (tweetContent: TweetContent) => {
  const hashtags = tweetContent.hashtags
    ? stringArrayToString(tweetContent.hashtags)
    : "";
  window.open(
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetContent.text
    )}` +
      (tweetContent.url ? `&url=${tweetContent.url}` : "") +
      (tweetContent.hashtags ? `&hashtags=${hashtags}` : "")
  );
};
