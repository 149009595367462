import * as _ from "lodash";

export function mapToObj<V>(map: Map<string, V>): { [key: string]: V };
export function mapToObj<V>(map: Map<any, V>): { [key: string]: V } {
  return _.fromPairs([...map]);
}

export function objToMap<V>(obj: { [key: string]: V }): Map<string, V>;
export function objToMap<V>(obj: { [key: string]: V }): Map<any, V> {
  return new Map(Object.entries(obj));
}
