import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from "firebase/firestore";
import {
  Guild,
  Problem,
  ProblemFeedBack,
  ProblemProgress,
  Ranking,
  Solution,
  Thumbnail,
  User,
} from "../../interface";
import { objToMap } from "../../util/util";

const objToProblemProgress = (prop: {
  [key: string]: { [key: string]: Timestamp };
}): Map<string, ProblemProgress> => {
  let result = new Map<string, ProblemProgress>();
  Object.keys(prop).forEach((key) => {
    const map = objToMap(prop[key]) as ProblemProgress;
    result.set(key, map);
  });
  return result;
};

export const userConverter = {
  toFirestore(post: User): DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): User {
    const doc = snapshot;
    const newUserData: User = {
      userName: doc.data()?.userName,
      picture: doc.data()?.picture,
      joinedGuildId: doc.data()?.joinedGuildId,
      originalGuildId: doc.data()?.originalGuildId,
      problemProgress: objToProblemProgress(doc.data()?.problemProgress),
    };
    return newUserData;
  },
};

export const problemConverter = {
  toFirestore(post: Problem): DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Problem {
    const doc = snapshot;
    const newProblemData = doc.data() as Problem;
    return newProblemData;
  },
};

export const thumbnailConverter = {
  toFirestore(post: Thumbnail): DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Thumbnail {
    const doc = snapshot;
    const newTumbnailData = doc.data() as Thumbnail;

    return newTumbnailData;
  },
};

export const problemFeedBackConverter = {
  toFirestore(post: ProblemFeedBack): DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ProblemFeedBack {
    const doc = snapshot;
    const newProblemFeedBackData = doc.data() as ProblemFeedBack;
    return newProblemFeedBackData;
  },
};

export const guildConverter = {
  toFirestore(post: Guild): DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Guild {
    const doc = snapshot;
    const newGuildData: Guild = {
      name: doc.data()?.name,
      memberIds: doc.data()?.memberIds,
      problemProgress: objToProblemProgress(doc.data()?.problemProgress),
      active: doc.data()?.active,
      guildOwner: doc.data()?.guildOwner,
    };
    return newGuildData;
  },
};

export const solutionConverter = {
  toFirestore(post: Solution): DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Solution {
    const doc = snapshot;
    const newSolutionData = doc.data() as Solution;
    return newSolutionData;
  },
};

export const rankingConverter = {
  toFirestore(post: Ranking): DocumentData {
    return post;
  },
  fromFirestore(snapshot: DocumentData): Ranking {
    const doc = snapshot;
    console.log(doc.data());
    return objToMap(doc.data()) as Ranking;
  },
};
