import { useEffect, useRef, useState } from "react";
import { getUserName, loadGuildData } from "../api/dataLoader";
import { initialGuild } from "../data/initialData";
import { Guild } from "../interface";

export const useGuildMemberNameList = (guildData: Guild) => {
  const [memberNameList, setMemberNameList] = useState([
    { memberName: "", memberId: "" },
  ]);
  useEffect(() => {
    const f = async () => {
      const result = await Promise.all(
        guildData.memberIds.map(async (memberId) => {
          if (!memberId) {
            return { userName: "", uid: "" };
          }
          const oneMemberName = await getUserName(memberId);
          return oneMemberName;
        })
      );
      const newMemberNameList = result
        .filter((value) => value.userName !== "")
        .map((v) => {
          return { memberName: v.userName, memberId: v.uid };
        });
      setMemberNameList(newMemberNameList);
    };
    f();
  }, [guildData.memberIds]);
  return memberNameList;
};

export const useGUildData = (joinedGuildId: string | null | undefined) => {
  const [guildData, setGuildData] = useState(initialGuild);
  const stopGuildDataSnapshot = useRef(() => {});
  useEffect(() => {
    switch (joinedGuildId) {
      case null:
      case undefined:
      case "":
        //ログインしていない、またはゲストモード
        stopGuildDataSnapshot.current();
        setGuildData(initialGuild);
        break;
      default:
        stopGuildDataSnapshot.current();
        stopGuildDataSnapshot.current = loadGuildData(
          joinedGuildId,
          setGuildData
        );
        break;
    }
  }, [joinedGuildId]);
  return guildData;
};
