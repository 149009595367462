import { Timestamp } from "firebase/firestore";
import { getUserNameFromUidArray, loadRanking } from "../api/dataLoader";
import { BehaviorId, Ranking } from "../interface";
import { useEffect, useState } from "react";

let stopRankingSnapshot = () => {};

export const useRankingDataWithName = (
  problemId: string,
  behavior: BehaviorId
) => {
  const [rankingName, setRankingName] = useState<
    { uid: string; userName: string; time: Timestamp }[] | undefined | null
  >(null);
  const rankingData = useRankingData(problemId);

  useEffect(() => {
    const f = async () => {
      console.log(rankingData);
      if (rankingData) {
        const correctRankingData = rankingData.get(behavior);
        if (correctRankingData) {
          const uidArray = correctRankingData.map((v) => v.uid);
          const newRankingName = await getUserNameFromUidArray(uidArray);
          setRankingName(
            newRankingName.map((v, i) => {
              return { ...v, time: correctRankingData[i].time };
            })
          );
        }
      } else {
        //rankingData === null | undefined
        setRankingName(rankingData);
      }
    };
    f();
  }, [rankingData, behavior]);
  return rankingName;
};

export const useRankingData = (problemId: string) => {
  const [ranking, setRanking] = useState<null | Ranking | undefined>(null);

  useEffect(() => {
    const f = async () => {
      stopRankingSnapshot();
      stopRankingSnapshot = await loadRanking(problemId, setRanking);
    };
    f();
  }, [problemId]);

  return ranking;
};
