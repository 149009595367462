import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useIsLogined } from "../../hooks/userDataHooks";
import { User } from "../../interface";
import { PageTitle } from "../typography/PageTitle";
import { ChangeUserName } from "./userConfigContents/ChangeUserName";

export const UserConfig = ({
  uid,
  userData,
}: {
  uid: string;
  userData: User;
}) => {
  const isLogined = useIsLogined(uid);
  return (
    <>
      <Grid item xs={12}>
        <PageTitle>ユーザー設定</PageTitle>
      </Grid>

      <Card>
        <CardContent>
          <Typography variant="h4" component="h1">
            ユーザー情報
          </Typography>
          <p>アカウント名: {userData.userName}</p>
          <p>ユーザーID: {uid}</p>
        </CardContent>
      </Card>
      {isLogined && <ChangeUserName uid={uid} />}
    </>
  );
};
