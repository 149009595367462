import { Button, Grid } from "@mui/material";
import { fabric } from "fabric";
import { useState } from "react";

type Prop = {
  canvas: fabric.Canvas | undefined;
  setCanvas: (prop: fabric.Canvas) => void;
};

export const CanvasButtons = (prop: Prop) => {
  const { canvas, setCanvas } = prop;
  const [history, setHistory] = useState<Array<fabric.Object>>([]);

  const makeDrawingModeOff = () => {
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.isDrawingMode = false;
      setCanvas(newCanvas);
    }
  };
  const makeDrwaingModeOn = () => {
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.isDrawingMode = true;
      setCanvas(newCanvas);
    }
  };

  const [expectedHandWriteLengh, setExpectedHandWriteLength] = useState(0);

  const clearHandwrite = () => {
    if (canvas !== undefined) {
      canvas._objects.splice(0);
      canvas.renderAll();
      setExpectedHandWriteLength(0);
    }
  };

  const undo = () => {
    if (canvas === undefined) {
      return;
    }
    if (canvas._objects.length > 0) {
      const temp = canvas._objects.pop();
      if (temp !== undefined) {
        history.push(temp);
      }
      setExpectedHandWriteLength(canvas._objects.length);
      canvas.requestRenderAll();
    }
  };
  const redo = () => {
    if (canvas === undefined) {
      return;
    }
    if (
      history.length > 0 &&
      expectedHandWriteLengh === canvas._objects.length
    ) {
      const temp = history.pop();
      if (temp !== undefined) {
        canvas.add(temp);
        setExpectedHandWriteLength(expectedHandWriteLengh + 1);
      }
    }
    canvas.requestRenderAll();
  };

  const buttonArray = [
    { name: "Drag Mode", function: makeDrawingModeOff },
    { name: "Pen Mode", function: makeDrwaingModeOn },
    { name: "clear", function: clearHandwrite },
    { name: "undo", function: undo },
    { name: "redo", function: redo },
  ];

  return (
    <Grid component="div" sx={{ my: 1 }}>
      {buttonArray.map((v) => (
        <Button
          variant="contained"
          color="primary"
          onClick={v.function}
          sx={{ mx: 0.5 }}
          key={v.name}
        >
          {v.name}
        </Button>
      ))}
    </Grid>
  );
};
