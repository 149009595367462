import { Button, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useThumbnail } from "../../hooks/problemHooks";
import { useProgress } from "../../hooks/userDataHooks";
import {
  OpeningCondition,
  ProblemProgress,
  UserAndGuildAndUid,
} from "../../interface";
import { conditionChecker } from "../../problemConditionCheck/conditionChecker";
import { thumbnailSortAndFilter } from "./thumbnailFilter/lib/thumbnailSortAndFilter";
import { ThumbnailSortAndFilterMethod } from "./thumbnailFilter/lib/types";
import ThumbnailViewer2Layout from "./ThumbnailViewer2Layout";

type ThumbnailViewer2Props = {
  thumbnailSortAndFilterCondition: ThumbnailSortAndFilterMethod;
  userAndGuildAndUid: UserAndGuildAndUid;
  now: Date | undefined;
};

export const ThumbnailViewer2 = (props: ThumbnailViewer2Props) => {
  const { thumbnailSortAndFilterCondition, userAndGuildAndUid, now } = props;
  const { userData, guildData } = userAndGuildAndUid;
  const problemProgress = useProgress(userAndGuildAndUid);
  const thumbnailData = useThumbnail();
  const navigate = useNavigate();

  const handleDisplayProblem = useCallback(
    (
      problemId: string,
      openingCondition: OpeningCondition,
      problemProgress: Map<string, ProblemProgress>,
      now: Date | undefined
    ) => {
      console.log(openingCondition);
      if (openingCondition !== undefined) {
        conditionChecker(openingCondition, problemProgress, now) &&
          navigate("/problem/" + problemId);
      }
    },
    [navigate]
  );

  return (
    <>
      {thumbnailSortAndFilter(
        thumbnailData,
        thumbnailSortAndFilterCondition,
        userData,
        guildData
      )
        .filter((v, i) =>
          conditionChecker(v.appearanceCondition, problemProgress, now)
        )
        .map((v, i) => (
          <ThumbnailViewer2Layout
            key={v.problemId}
            sxBackground={{
              backgroundImage: `url(${v.thumbnailImage})`,
              backgroundColor: "#7fc7d9", // Average color of the background image.
              backgroundPosition: "center",
            }}
          >
            {/* Increase the network loading priority of the background image. */}
            {v.thumbnailImage && (
              <img
                style={{ display: "none" }}
                src={v.thumbnailImage}
                alt="increase priority"
              />
            )}
            <Typography color="inherit" align="center" variant="h2">
              {v.title ?? ""}
            </Typography>
            <Typography
              color="inherit"
              align="center"
              variant="h5"
              sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
            >
              {v.authorComment}
            </Typography>
            {conditionChecker(v.openingCondition, problemProgress, now) && (
              <div
                onClick={() => {
                  console.log("click");
                  handleDisplayProblem(
                    v.problemId,
                    v.openingCondition,
                    problemProgress,
                    now
                  );
                }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  component="div"
                  sx={{ minWidth: 200 }}
                >
                  Solve
                </Button>
              </div>
            )}
            <Typography
              color="inherit"
              align="center"
              variant="h5"
              sx={{ mb: 1, mt: { sx: 4, sm: 10 } }}
            >
              Difficulty
            </Typography>
            <Typography color="inherit" align="center" variant="h5">
              {v.difficulty.map((v2, i) => (
                <>{v2.icon}</>
              ))}
            </Typography>
          </ThumbnailViewer2Layout>
        ))}
    </>
  );
};
