import {
  Container,
  CssBaseline,
  GlobalStyles,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import "firebase/auth";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from ".";
import { checkSessionCookie } from "./api/sso/checkSessionCookie";
import { loginWithCustomToken } from "./api/sso/loginWithCustomToken";
import {
  CommonHeader,
  CommonHeaderProps,
} from "./components/commonContents/CommonHeader";
import { Config } from "./components/Config";
import { FirstLogin } from "./components/configContents/firstLogin/FirstLogin";
import { SuccessCreateNewAccount } from "./components/configContents/firstLogin/firstLoginContents/Success";
import { GuildConfig } from "./components/configContents/GuildConfig";
import { UserConfig } from "./components/configContents/UserConfig";
import { NotFound } from "./components/NotFound";
import { NowLoading } from "./components/NowLoading";
import { ProblemPage } from "./components/Problem";
import ProblemSelect from "./components/ProblemSelect";
import { Rule } from "./components/Rule";
import "./css/App.css";
import { useGUildData } from "./hooks/guildDataHooks";
import { useTime } from "./hooks/timeHooks";
import { useUserData } from "./hooks/userDataHooks";
import { logger } from "./lib/logger";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#fbc21d",
      dark: "#90a4ae",
    },
    secondary: {
      main: "#fa4705",
    },
    success: {
      main: "#00c853",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2196f3",
    },
    background: {
      paper: "#111",
      default: "#000",
    },
    action: {
      disabledBackground: "#EADAAF",
      disabled: "#ffffff",
    },
  },
};

const theme = createTheme(themeOptions);

const App = () => {
  //userData取得
  const [uid, setUid] = useState<null | string>(null);
  const { userData, isUserDataLoaded, needCreateUser, setNeedCreateUser } =
    useUserData(uid);
  const guildData = useGUildData(userData.joinedGuildId);

  const [user, loading] = useAuthState(auth);

  const now = useTime();
  const navigate = useNavigate();
  const location = useLocation();

  const isLocalDebug = process.env.REACT_APP_CONTEXT === "LOCAL";

  useEffect(() => {
    //1. puzsq側でログイン状況の確認
    if (loading) {
      return;
    }
    //2. sessionCookieで認証
    //parentUidがバグってる
    checkSessionCookie().then(({ uid, customToken }) => {
      if (user !== null) {
        if ((uid !== null && customToken !== null) || isLocalDebug) {
          if (uid === user.uid || isLocalDebug) {
            //2-1. 矛盾しない
            logger("case2-1", user.uid);
            setUid(user.uid);
          } else {
            logger("case2-2");
            logger(uid, user.uid);
            //2-2. 矛盾しているのでログアウト+sessionCookieをもとに再ログイン
            signOut(auth).then(() => {
              loginWithCustomToken(customToken!).then((result) => {
                setUid(result);
              });
            });
          }
        } else {
          logger("case2-3");
          //2-3. sessionCookie再発行する
          window.location.href = `https://logicpuzzle.app/refresh/${process.env.REACT_APP_LOGIN_URL}`;
        }
      } else {
        if (uid !== null && customToken !== null) {
          logger("case4-1");
          //4-1. sessionCookieをもとにログイン
          loginWithCustomToken(customToken).then((result) => {
            setUid(result);
          });
        } else {
          logger("case4-2");
          //4-2. ゲストモード
          setUid("");
        }
      }
    });
  }, [loading, auth, user]);
  // ユーザーIDが変わった時

  useEffect(() => {
    if (needCreateUser === undefined) {
      //処理中
      return;
    } else if (!needCreateUser) {
      //アカウント作成不要
      if (
        location.pathname === "/login" ||
        location.pathname === "/firstlogin"
      ) {
        navigate("/");
      }
    } else {
      if (location.pathname !== "/firstlogin/success") {
        //アカウント新規作成
        navigate("/firstlogin");
      }
    }
  }, [needCreateUser, location.pathname, navigate]);

  if (uid === null) {
    return (
      <Wrapper userData={userData} guildData={guildData} uid={uid}>
        <NowLoading />
      </Wrapper>
    );
  }

  return (
    <Wrapper userData={userData} guildData={guildData} uid={uid}>
      {!isUserDataLoaded ? (
        <NowLoading />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <ProblemSelect
                guildData={guildData}
                userData={userData}
                uid={uid}
                now={now}
              />
            }
          />
          <Route
            path="problem/:problemId"
            element={
              <Container maxWidth="xl">
                <ProblemPage
                  guildData={guildData}
                  userData={userData}
                  uid={uid}
                  now={now}
                />
              </Container>
            }
          />

          <Route
            path="config"
            element={
              <Container maxWidth="xl">
                <Config uid={uid} />
              </Container>
            }
          >
            <Route
              path="user"
              element={<UserConfig uid={uid} userData={userData} />}
            />
            <Route
              path="guild"
              element={
                <GuildConfig
                  uid={uid}
                  userData={userData}
                  guildData={guildData}
                />
              }
            />
          </Route>
          <Route
            path="about"
            element={
              <Container maxWidth="xl">
                <Rule uid={uid} userData={userData} guildData={guildData} />
              </Container>
            }
          />
          <Route
            path="login"
            element={
              <Container maxWidth="xl">
                <NowLoading />
              </Container>
            }
          />
          <Route
            path="firstlogin"
            element={
              <Container maxWidth="xl">
                <FirstLogin
                  uid={uid}
                  needCreateUser={needCreateUser}
                  setNeedCreateUser={setNeedCreateUser}
                />
              </Container>
            }
          />
          <Route
            path="firstlogin/success"
            element={
              <Container maxWidth="xl">
                <SuccessCreateNewAccount />
              </Container>
            }
          />
          <Route path="loading" element={<NowLoading />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Wrapper>
  );
};

export default App;

export const Wrapper: React.FC<CommonHeaderProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: "#000" },
        }}
      />
      <CommonHeader
        userData={props.userData}
        guildData={props.guildData}
        uid={props.uid}
      >
        <Box sx={{ mt: 9, mx: "auto", px: 1 }}>{props.children}</Box>
      </CommonHeader>
    </ThemeProvider>
  );
};
