import { HomeButton } from "../../../button/HomeButton";

export const SuccessCreateNewAccount = () => {
  return (
    <>
      <div>アカウント作成に成功しました。</div>
      <HomeButton />
    </>
  );
};
