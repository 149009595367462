import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Guild, User } from "../interface";
import { HomeButton } from "./button/HomeButton";
import { PageTitle } from "./typography/PageTitle";

type RuleProps = {
  guildData: Guild;
  userData: User;
  uid: string;
};

export const Rule = (props: RuleProps) => {
  const { guildData, userData, uid } = props;

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const onResize = useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, [setSize]);

  useEffect(() => {
    window.addEventListener("resize", onResize, true);
    return () => {
      window.removeEventListener("resize", onResize, true);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <PageTitle>Abyss</PageTitle>
      </Grid>
      <Grid item xs={12}>
        ここにabyssの説明が入る 説明するべき事
        ・ログインしないとデータが保存されないよ!
        <ul>
          <li>
            トラブルや疑問点などがありましたら
            <a
              href="https://twitter.com/AbyssOfPuzzles"
              style={{ color: "#fff" }}
            >
              深淵公式アカウント
            </a>
            にお問い合わせください。
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <HomeButton />
      </Grid>
    </>
  );
};
