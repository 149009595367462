import { getAuth, signOut } from "firebase/auth";
export const logout = async () => {
  //firebaseからのログアウト
  const auth = getAuth();
  await signOut(auth);

  if (process.env.REACT_APP_CONTEXT !== "LOCAL") {
    //親プロジェクトへの遷移
    window.location.href = `https://logicpuzzle.app/logout/${process.env.REACT_APP_LOGIN_URL}`;
  }
};
