import { TimeCondition } from "../interface";

export const timeConditionChecker = (
  timeCondition: TimeCondition,
  now: Date | undefined
) => {
  let ret = true;
  if (timeCondition.data.openTime) {
    if (!now) {
      return false;
    }
    ret = ret && timeCondition.data.openTime.toDate() < now;
  }
  if (timeCondition.data.closeTime) {
    if (!now) {
      return false;
    }
    ret = ret && timeCondition.data.closeTime.toDate() > now;
  }

  return ret;
};
