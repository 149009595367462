import { ImageContentDataObj } from "../../../interface";
import { Canvas } from "../../canvas/Canvas";

export const ImageContentViewer = (prop: ImageContentDataObj) => {
  return (
    <div>
      {prop.data.usePenTool ? (
        <Canvas imageUrl={prop.data.image} />
      ) : (
        <img src={prop.data.image} alt={""} />
      )}
    </div>
  );
};
