import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { receiveGuildInviteCode } from "../../../api/cloudFunctionsCaller";
import { Guild } from "../../../interface";
import { BackdropWithCircle } from "../backdrop/BackdropWithCircle";

export const ReceiveGuildInviteCode = ({
  guildId,
  uid,
  guildData,
}: {
  guildId: string;
  uid: string;
  guildData: Guild;
}) => {
  const [inviteCode, setInviteCode] = useState("");
  const [isBackdropOpened, setIsBackdropOpened] = useState(false);

  const [isSolo, setIsSolo] = useState(true);

  useEffect(() => {
    setIsSolo(guildData.memberIds.length === 1);
  }, [guildData.memberIds]);

  const handleInviteCode = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInviteCode(e.target.value);
    setMessage("");
  };

  const [message, setMessage] = useState("");

  const handleButton = async () => {
    setIsBackdropOpened(true);

    const result = await receiveGuildInviteCode(inviteCode, guildId, uid);
    if (!result?.success) {
      setMessage(
        "ギルドに参加できませんでした。招待コードが正しいか、また、招待コードが有効かお確かめください。このメッセージが複数回出る場合、時間をおいてお試しください。"
      );
    } else {
      setMessage("ギルドに参加しました。");
    }

    setIsBackdropOpened(false);
  };
  return (
    <>
      <BackdropWithCircle isBackdropOpened={isBackdropOpened} />
      <Grid item xs={12}>
        <p>招待コードを利用して既存のギルドに参加できます。</p>
        <Grid item>
          <TextField
            value={inviteCode}
            label="招待コード"
            variant="outlined"
            onChange={(e) => {
              handleInviteCode(e);
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12} component="div" sx={{ my: 2 }}>
          {isSolo ? (
            <Button variant="contained" onClick={() => handleButton()}>
              ギルドに参加
            </Button>
          ) : (
            <>
              <Button variant="contained" onClick={() => handleButton()}>
                ギルドを併合
              </Button>
            </>
          )}
          <div>{message}</div>
        </Grid>
      </Grid>
    </>
  );
};
