import { Grid } from "@mui/material";
import { fabric } from "fabric";
import { CanvasButtons } from "./canvasConfigcomponents/CanvasButtons";
import { ColorSelect } from "./canvasConfigcomponents/ColorSelect";
import { PenWidthSelect } from "./canvasConfigcomponents/PenWidthSelect";

type Prop = {
  canvas: fabric.Canvas | undefined;
  setCanvas: (prop: fabric.Canvas) => void;
};

export const CanvasUtil = (prop: Prop) => {
  const { canvas, setCanvas } = prop;
  return (
    <div>
      <CanvasButtons canvas={canvas} setCanvas={setCanvas} />
      <ColorSelect canvas={canvas} setCanvas={setCanvas} />
      <PenWidthSelect canvas={canvas} setCanvas={setCanvas} />
    </div>
  );
};
