import { Button, Card, CardContent, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useIsLogined } from "../../hooks/userDataHooks";
import { ConfigProps } from "../Config";
import { PageTitle } from "../typography/PageTitle";
import { SectionTitle } from "../typography/SectionTitle";
import { ChangeGuildName } from "./guildConfigContents/ChangeGuildName";
import { ChangeGuildOwner } from "./guildConfigContents/ChangeGuildOwner";
import { LeaveGuild } from "./guildConfigContents/LeaveGuild";
import { MakeGuildInviteCode } from "./guildConfigContents/MakeGuildInviteCode";
import { ReceiveGuildInviteCode } from "./guildConfigContents/ReceiveGuildInviteCode";
import { ShowGuildMemberList } from "./guildConfigContents/ShowGuildMemberList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

export const GuildConfig = (props: ConfigProps) => {
  const { guildData, userData, uid } = props;
  const classes = useStyles();
  const isLogined = useIsLogined(uid);
  const navigate = useNavigate();

  return isLogined ? (
    <>
      <Grid item xs={12}>
        <PageTitle>ギルド設定</PageTitle>
      </Grid>
      {(userData.userName ?? "") === "" ? (
        <>
          <p>ユーザー名設定後ギルド機能をご利用いただけます。</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/config/user")}
            sx={{ mx: 0.5 }}
          >
            ユーザー設定
          </Button>
        </>
      ) : (
        <Grid item xs={12}>
          <ShowGuildMemberList
            guildData={guildData}
            userData={userData}
            uid={uid}
          />
          {guildData.name === null ? (
            <>
              <Card>
                <CardContent>
                  <SectionTitle>ギルドを新規作成</SectionTitle>
                  <ChangeGuildName isNewGuild={true} uid={uid} />
                </CardContent>
              </Card>
              {typeof userData.joinedGuildId === "string" && (
                <Card>
                  <CardContent>
                    <SectionTitle>ギルドに参加</SectionTitle>
                    <ReceiveGuildInviteCode
                      guildId={userData.joinedGuildId}
                      guildData={guildData}
                      uid={uid}
                    />
                  </CardContent>
                </Card>
              )}
            </>
          ) : userData.originalGuildId === userData.joinedGuildId ? (
            <>
              <Card>
                <CardContent>
                  <SectionTitle>ギルドオーナーの変更</SectionTitle>
                  <ChangeGuildOwner
                    guildData={guildData}
                    userData={userData}
                    uid={uid}
                  />
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <SectionTitle>ギルド名を変更する</SectionTitle>
                  <ChangeGuildName isNewGuild={false} uid={uid} />
                </CardContent>
              </Card>
              {typeof userData.joinedGuildId === "string" && (
                <>
                  <Card>
                    <CardContent>
                      <SectionTitle>ギルド招待コードを発行する</SectionTitle>
                      <MakeGuildInviteCode
                        guildId={userData.joinedGuildId}
                        guildName={guildData.name}
                        uid={uid}
                      />
                    </CardContent>
                  </Card>

                  <Card>
                    <CardContent>
                      <SectionTitle>ギルドを併合する</SectionTitle>
                      <ReceiveGuildInviteCode
                        guildId={userData.joinedGuildId}
                        guildData={guildData}
                        uid={uid}
                      />
                    </CardContent>
                  </Card>
                </>
              )}
            </>
          ) : (
            <Card>
              <CardContent>
                <LeaveGuild uid={uid} />
              </CardContent>
            </Card>
          )}
        </Grid>
      )}
    </>
  ) : (
    <>
      <p>Twitterにログインしていない場合ギルド機能は利用できません。</p>
    </>
  );
};
