import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CardContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/sso/logout";
import { isGuildSystemOn } from "../../igSystemConfig";
import { Guild, User } from "../../interface";

export type CommonHeaderProps = {
  userData: User | null | undefined;
  guildData: Guild;
  uid: string | null;
};

export const CommonHeader: React.FC<CommonHeaderProps> = (props) => {
  const { userData, guildData, uid } = props;

  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Button
            key={"attention"}
            onClick={() => {
              navigate("/about");
            }}
            color="inherit"
            sx={{
              my: 2,
              color: "white",
              display: "block",
            }}
          >
            ABOUT
          </Button>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Abyss
          </Typography>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            {userData?.userName === null || userData?.userName === undefined ? (
              <Button
                color="inherit"
                onClick={() => {
                  window.location.href = `https://logicpuzzle.app/login/${process.env.REACT_APP_LOGIN_URL}`;
                }}
              >
                Login/Signup
              </Button>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="メニューを開く">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={userData.userName}
                      src={userData.picture ?? ""}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  onClose={handleCloseUserMenu}
                  open={Boolean(anchorElUser)}
                >
                  <CardContent>
                    {uid !== "" || uid === null ? userData.userName : "ゲスト"}{" "}
                    ({isGuildSystemOn && (guildData.name ?? "未所属")})<br />
                  </CardContent>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate("/config/user");
                    }}
                    sx={{ width: 320 }}
                  >
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>ユーザー設定</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate("/about");
                    }}
                    sx={{ width: 320 }}
                  >
                    <ListItemIcon>
                      <HelpIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>このサイトについて</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logout();
                      handleCloseUserMenu();
                    }}
                    sx={{ width: 320 }}
                  >
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>ログアウト</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {props.children}
    </>
  );
};
