import { useEffect, useState } from "react";
import {
  loadProblemData,
  loadSolutionData,
  loadThumbnail,
} from "../api/dataLoader";
import {
  OpeningCondition,
  Problem,
  Solution,
  Thumbnail,
  UserAndGuildAndUid,
} from "../interface";
import { conditionChecker } from "../problemConditionCheck/conditionChecker";
import { useProgress } from "./userDataHooks";

//TODO: 未解禁問題のサムネイルの取得制限
// 開放済みフロアのサムネイルデータ取得
export const useThumbnail = () => {
  const [thumbnailData, setThumbnail] = useState(new Map<string, Thumbnail>());
  useEffect(() => {
    const f = async () => {
      const tempThumbnail = await loadThumbnail();
      setThumbnail(tempThumbnail);
    };
    f();
  }, []);
  return thumbnailData;
};

export const useOneThumbnail = (problemId: string) => {
  const thumbnailData = useThumbnail();
  const [oneThumbnailData, setOneThumbnailData] = useState<
    Thumbnail | undefined
  >(undefined);

  useEffect(() => {
    setOneThumbnailData(thumbnailData.get(problemId));
  }, [thumbnailData, problemId]);
  return oneThumbnailData;
};

export const useIsProblemRevealed = (
  prop: UserAndGuildAndUid,
  problemId: string,
  now: Date | undefined
) => {
  const problemProgress = useProgress(prop);
  const [isProblemRevealed, setIsProblemRevealed] = useState<
    boolean | undefined
  >(undefined);
  const thumbnailData = useThumbnail();
  const [problemOpeningCondition, setProblemOpeningCondition] =
    useState<OpeningCondition>(null);

  useEffect(() => {
    const newProblemOpeningCondition =
      thumbnailData.get(problemId)?.openingCondition;
    setProblemOpeningCondition(newProblemOpeningCondition ?? null);
  }, [thumbnailData, problemId]);

  useEffect(() => {
    if (problemOpeningCondition) {
      setIsProblemRevealed(
        conditionChecker(problemOpeningCondition, problemProgress, now)
      );
    } else {
      setIsProblemRevealed(false);
    }
  }, [problemOpeningCondition, problemProgress, now]);

  return isProblemRevealed;
};

// 1フロアデータ取得
export const useOneProblemData = (
  prop: UserAndGuildAndUid,
  problemId: string,
  now: Date | undefined
) => {
  const isProblemRevealed = useIsProblemRevealed(prop, problemId, now);
  const [problemData, setProblemData] = useState<Problem | undefined>(
    undefined
  );
  useEffect(() => {
    const f = async () => {
      const newProblem = await loadProblemData(problemId);
      setProblemData(newProblem);
    };

    isProblemRevealed && f();
  }, [problemId, isProblemRevealed]);
  return problemData;
};

export const useIsProblemSolved = (
  prop: UserAndGuildAndUid,
  problemId: string
) => {
  const problemProgress = useProgress(prop);
  const [isProblemSolved, setIsProblemSolved] = useState(false);
  useEffect(() => {
    if (problemProgress.get(problemId)?.has("correct") ?? false) {
      setIsProblemSolved(true);
    } else {
      setIsProblemSolved(false);
    }
  }, [problemProgress, problemId]);
  return isProblemSolved;
};

export const useOneSolutionData = (
  prop: UserAndGuildAndUid,
  problemId: string,
  now: Date | undefined,
  openSolutionForGuest: boolean
) => {
  const isProblemRevealed = useIsProblemRevealed(prop, problemId, now);
  const isProblemSolved = useIsProblemSolved(prop, problemId);
  const [solutionData, setSolutionData] = useState<Solution | undefined>(
    undefined
  );
  useEffect(() => {
    const f = async () => {
      const newSolution = await loadSolutionData(problemId);
      setSolutionData(newSolution);
    };

    ((isProblemRevealed && isProblemSolved) || openSolutionForGuest) && f();
  }, [problemId, isProblemRevealed, isProblemSolved, openSolutionForGuest]);
  return solutionData;
};
