import { Backdrop, CircularProgress } from "@mui/material";

export const BackdropWithCircle = ({
  isBackdropOpened,
}: {
  isBackdropOpened: boolean;
}) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBackdropOpened}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
