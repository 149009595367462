import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import {
  initialGuild,
  initialProblem,
  initialSolution,
} from "../data/initialData";
import { Guild, Ranking, Thumbnail, User, UserWithId } from "../interface";
import { hashText } from "../util/hashText";
import {
  guildConverter,
  problemConverter,
  rankingConverter,
  solutionConverter,
  thumbnailConverter,
  userConverter,
} from "./util/firebaseConverter";

export const getUserParentUid = (uid: string): Promise<string> => {
  const db = getFirestore();
  const docRef = doc(db, "user", uid);

  console.log("data load");

  return getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        return doc.data()?.parentUid;
      } else {
        return undefined;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const loadUserData = (
  uid: string | undefined,
  setUserData: (userData: User) => void,
  setUserDataLoaded: (arg: boolean) => void
) => {
  console.log("data load");

  if (uid === undefined) {
    return () => {};
  }
  const db = getFirestore();
  const docRef = doc(db, "user", uid).withConverter(userConverter);
  //let success = false;
  const stopUserDataSnapshot = onSnapshot(docRef, (doc) => {
    const newUserData = doc.data();
    if (newUserData) {
      setUserData(newUserData);
      //success = true;
    } else {
      console.log("No such document!");
    }
    setUserDataLoaded(true);
  });
  return stopUserDataSnapshot;
};

export const loadAllUserData = (setAllUserData: (g: UserWithId[]) => void) => {
  const db = getFirestore();
  const colRef = collection(db, "user").withConverter(userConverter);
  //let success = false;
  const stopUserDataSnapshot = getDocs(colRef).then((col) => {
    const allUserData = col.docs.map((doc) => {
      return { ...doc.data(), uid: doc.id };
    });
    setAllUserData(allUserData);
  });
  return stopUserDataSnapshot;
};

export const loadGuildData = (
  guildId: string,
  setGuildData: (guildData: Guild) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "guild", guildId).withConverter(guildConverter);
  //let success = false;
  const stopGuildDataSnapshot = onSnapshot(docRef, (doc) => {
    const newGuildData = doc.data();
    if (newGuildData) {
      setGuildData(newGuildData);
      //success = true;
    } else {
      setGuildData(initialGuild);
      console.log("No such document!");
    }
  });
  return stopGuildDataSnapshot;
};

export const loadAllGuildData = (setAllGuildData: (g: Guild[]) => void) => {
  const db = getFirestore();
  const colRef = collection(db, "guild").withConverter(guildConverter);
  //let success = false;
  const stopGuildDataSnapshot = onSnapshot(colRef, (col) => {
    const allGuildData = col.docs.map((doc) => doc.data());
    setAllGuildData(allGuildData);
  });
  return stopGuildDataSnapshot;
};

export const loadRankingMetaData = async (
  setRankingMeta: (rankingMetaData: { ranking: Date }) => void
) => {
  const db = getFirestore();
  getDoc(doc(db, "doc", "ranking")).then((doc) => {
    const data = {
      ranking: doc.data()?.ranking.toDate(),
    };
    setRankingMeta(data);
  });
};

export const loadGuildAdminData = async (
  guildId: string,
  setGuildInviteCode: (inviteCode: string) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "guildAdmin", guildId);
  return await getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        const newInviteCode: string = doc.data()?.inviteCode;
        if (newInviteCode) {
          setGuildInviteCode(newInviteCode);
          return true;
        } else {
          console.log("no data :doc.data().?inviteCode");
          return false;
        }
      } else {
        console.log("No such document!");
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return false;
    });
};

export const getRevealedProblemNumberSnapshot = async (
  setRevealedProblemNumber: (prop: number) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "revealedProblemNumber", "revealedProblemNumber");

  const stopRevealedProblemNumberSnapshot = onSnapshot(docRef, (doc) => {
    if (doc) {
      if (typeof doc.data()?.revealedProblemNumber === "number") {
        setRevealedProblemNumber(doc.data()?.revealedProblemNumber);
      } else {
        console.log("No revealedProblemNumber or type error");
      }
    }
  });
  return stopRevealedProblemNumberSnapshot;
};

export const getUserName = async (uid: string) => {
  const db = getFirestore();
  const docRef = doc(db, "user", uid);
  const userName = await getDoc(docRef).then((doc) => {
    let newMemberName = "";
    if (doc.exists()) {
      newMemberName = doc.data()?.userName;
    }
    return newMemberName;
  });
  return { userName, uid };
};

export const getUserNameFromUidArray = async (uidArray: string[]) => {
  const promiseArray = new Array<Promise<{ uid: string; userName: string }>>();
  uidArray.forEach((v) => promiseArray.push(getUserName(v)));
  const result = await Promise.allSettled(promiseArray);
  const nameArray = new Array<{ uid: string; userName: string }>();
  result.forEach((v, i) => {
    if (v.status === "fulfilled") {
      nameArray.push(v.value);
    } else {
      nameArray.push({
        uid: uidArray[i],
        userName: "-error- ユーザー名取得に失敗しました",
      });
    }
  });
  console.log(nameArray);

  return nameArray;
};

export const getUserDataOnce = async (uid: string) => {
  const db = getFirestore();
  const docRef = doc(db, "user", uid).withConverter(userConverter);
  const userData = (await getDoc(docRef)).data();
  return userData;
};

//TODO: functionsで解禁条件を確認して解禁状態の問題だけloadThumbnailの結果として返した方がベター
export const loadThumbnail = async () => {
  console.log("data load");

  const db = getFirestore();
  const colRef = collection(db, "thumbnail").withConverter(thumbnailConverter);
  const rawData = await getDocs(colRef);
  const thumbnailData = new Map<string, Thumbnail>();
  rawData.forEach((v) => {
    thumbnailData.set(v.id, v.data());
  });
  return thumbnailData;
};

//TODO problemのURLとfirestoreのドキュメント名の仕様
const problemIdTofirestoreId = async (problemId: string) => {
  return (await hashText(problemId)).slice(0, 8);
};

export const loadProblemData = async (problemId: string) => {
  console.log(problemId);
  const db = getFirestore();
  //const firestoreId = await problemIdTofirestoreId(problemId);
  const firestoreId = problemId;
  const docRef = doc(db, "problem", firestoreId).withConverter(
    problemConverter
  );
  return getDoc(docRef)
    .then(async (doc) => {
      const newProblemData = doc.data();
      if (newProblemData) {
        console.log(newProblemData.problemData);
        return newProblemData;
      } else {
        console.log("No such document!");
        return initialProblem;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return initialProblem;
    });
};

export const loadSolutionData = async (problemId: string) => {
  console.log(problemId);
  const db = getFirestore();
  //const firestoreId = await problemIdTofirestoreId(problemId);
  const firestoreId = problemId;
  const docRef = doc(db, "solution", firestoreId).withConverter(
    solutionConverter
  );
  return getDoc(docRef)
    .then(async (doc) => {
      const newSolutionData = doc.data();
      if (newSolutionData) {
        return newSolutionData;
      } else {
        console.log("No such document!");
        return initialSolution;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return initialSolution;
    });
};

export const loadRanking = async (
  problemId: string,
  setRanking: (prop: Ranking | undefined) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "ranking", problemId).withConverter(rankingConverter);

  const stopRankingSnapshot = onSnapshot(docRef, (doc) => {
    const data = doc.data();
    console.log(data);
    setRanking(data);
  });
  return stopRankingSnapshot;
};
